<script setup lang="ts">
const route = useRoute()
const open = useState('settings-modal-open', () => true)

onMounted(() => {
  open.value = true
})

function navigateBack() {
  if (route.query.returnRoute)
    navigateTo(route.query.returnRoute as string)
  else
    navigateTo('/')
}

const hideSidebar = computed(() => route.meta.hideSidebar)

watch(open, (isOpen) => {
  if (!isOpen)
    navigateBack()
})
</script>

<template>
  <UModal v-model="open" fullscreen :ui="{ fullscreen: 'flex p-10 dark:!bg-gray-950/85 !bg-gray-950/70 items-center justify-center' }">
    <div class="relative flex h-[50rem] max-h-full w-[80rem] max-w-full overflow-auto rounded-2xl border border-gray-300 bg-white dark:border-gray-700 dark:bg-gray-900">
      <div v-if="$slots.leftSidebar && !hideSidebar" class="flex w-64 shrink-0 flex-col overflow-y-auto border-r border-gray-300 p-4 dark:border-gray-700">
        <slot name="leftSidebar" />
      </div>

      <slot name="content">
        <div class="flex min-w-1 flex-1 flex-col">
          <slot />
        </div>
      </slot>
    </div>
  </UModal>
</template>
